<script setup lang="ts">
const { visible } = useLogin()
const tabActive = ref(2)
const { locale } = useI18n()
const isZhCN = computed(() => locale.value === 'zh')
const options = computed(() => {
  return !isZhCN.value
    ? [
        { label: '邮箱登录', value: 3 },
      ]
    : [{
        label: '手机号登录',
        value: 2,
      }]
})
const isPhone = ref(true)
watch(() => options.value, () => {
  tabActive.value = options.value[0].value
}, { immediate: true })
const isPassword = ref(true)
const step = ref(1)
const isReset = ref(false)
function forgetPwd() {
  step.value = 2
  isReset.value = true
}
const title = computed(() => {
  return isReset.value ? '重置密码' : tabActive.value === 2 ? '手机号注册' : '邮箱账号注册'
})
function reset() {
  step.value = 1
  isPassword.value = true
  isReset.value = false
}
watch(() => tabActive.value, () => {
  isPassword.value = true
})
watch(() => visible.value, () => {
  if (!visible.value) {
    reset()
  }
})
const localePath = useLocalePath()
</script>

<template>
  <a-modal
    v-model:visible="visible"
    unmount-on-close
    :footer="false"
    :width="940"
    simple
    modal-class="g-login__modal rd-24 p-0"
    hide-title
    :mask-closable="false"
    @cancel="visible = false"
  >
    <div flex justify-end class="g-login-modal relative w-940 rd-24">
      <span class="absolute right--70 top--10 h-42 w-42 flex cursor-pointer items-center justify-center rd-full bg-[--color-text-1] pl-1 text-20 text-white hover:op-80" @click="visible = false"><i class="icon-shanchu" /></span>
      <g-svg name="glodastory-logo" :width="111" :height="32" class="absolute left-30 top-30 text-black" />
      <!-- <div
        v-if="step === 1"
        class="absolute right-0 top-0 box-border h-64 w-64 flex cursor-pointer items-center justify-center border-32 border-b-transparent border-l-transparent border-r-[--color-fill-3] border-t-[--color-fill-3] rd-rt-24 border-solid text-[--color-text-3] hover:border-r-[--color-brand-3] hover:border-t-[--color-brand-3] hover:text-[--color-brand-6]"
      >
        <g-svg :name="isPhone ? 'login/qrcode' : 'login/phone'" :width="44" :height="44" class="mt-1" @click="isPhone = !isPhone" />
      </div> -->
      <div v-if="step === 2" class="absolute left-516 top-20 cursor-pointer text-16 text-[--color-text-2] hover:op-80" @click="reset">
        <g-svg name="login/left" />
      </div>
      <div box-border w-450 px-69 pb-50 pt-102 :class="{ '!px-58': locale === 'en' }">
        <template v-if="isPhone">
          <div
            v-if="step === 1"
            relative mb-52 flex justify-center
          >
            <div
              v-for="item in options"
              :key="item.value"
              class="text-28 text-[--color-text-5] font-500"
              @click="tabActive = item.value"
            >
              {{ $t(item.label) }}
            </div>
            <div
              v-if="options.length > 1"
              class="absolute bottom--4 left-60 h-3 w-40 rd-8 bg-[--color-brand-6] transition-all duration-300"
              :class="{ '!left-228': tabActive === 3 }"
            />
          </div>
          <div v-else class="mb-28 mt--38 flex justify-center text-28 text-[--color-text-5]">
            {{ $t(title) }}
          </div>
        </template>
        <LoginForm
          v-if="isPhone"
          :step="step"
          :type="tabActive"
          :isPassword="isPassword"
          :isReset="isReset"
          @change="isPassword = !isPassword"
          @forget="forgetPwd"
        />
        <LoginQrcode v-else />
        <div v-if="!isReset" class="flex flex-col items-center text-[--color-text-3]">
          <span v-if="isPassword && isPhone" mt-16 @click="step = step === 1 ? 2 : 1">
            {{ $t(step === 1 ? '暂无账号' : '已有账号') }}? <span class="cursor-pointer text-[--color-brand-6] hover:op-80">{{ $t(step === 1 ? '去注册' : '去登录') }}</span>
          </span>
          <span class="mt-16 flex flex-wrap justify-center text-12">
            <span>{{ $t('登录即表示已阅读并接受') }}</span>
            <!-- <NuxtLink :to="localePath('/agreement')" target="_blank" class="mx-4 cursor-pointer text-[--color-text-1] !text-12 hover:text-[--color-text-1] hover:underline" :hoverable="false">{{ $t('用户协议') }}</NuxtLink>
            <span>{{ $t('与') }}</span> -->
            <NuxtLink :to="localePath('/privacy')" target="_blank" class="mx-4 cursor-pointer text-[--color-text-1] !text-12 hover:text-[--color-text-1] hover:underline" :hoverable="false">{{ $t('隐私条款') }}</NuxtLink>
          </span>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<style scoped lang="less">
.g-login-modal {
  background: url(~/assets/images/login/bg.png) white no-repeat;
  background-size: 490 600;
  height: 600px;
}
</style>

<style>
.g-login__modal .arco-modal-body {
  overflow: unset !important;
}
</style>
