import { default as agreementY82GsiR81fMeta } from "/app/pages/agreement.vue?macro=true";
import { default as _91id_933W08RmjplwMeta } from "/app/pages/analysis/blogger/detail/[id].vue?macro=true";
import { default as ecommerceoPWDjRJwwFMeta } from "/app/pages/analysis/blogger/ecommerce.vue?macro=true";
import { default as increaseFanszdwuqL05TLMeta } from "/app/pages/analysis/blogger/increaseFans.vue?macro=true";
import { default as index1JzR77fDk9Meta } from "/app/pages/analysis/blogger/index.vue?macro=true";
import { default as _91id_93eEl2pjURgnMeta } from "/app/pages/analysis/live/detail/[id].vue?macro=true";
import { default as ecommerce5Bh2oBih01Meta } from "/app/pages/analysis/live/ecommerce.vue?macro=true";
import { default as indexObEO00WlCMMeta } from "/app/pages/analysis/live/index.vue?macro=true";
import { default as _91id_937ognwgTcAhMeta } from "/app/pages/analysis/products/detail/[id].vue?macro=true";
import { default as hotPushWjcxNu4Y2QMeta } from "/app/pages/analysis/products/hotPush.vue?macro=true";
import { default as hotSale1QPgdKQYnIMeta } from "/app/pages/analysis/products/hotSale.vue?macro=true";
import { default as indexskMVpjdDJjMeta } from "/app/pages/analysis/products/index.vue?macro=true";
import { default as bloggerInteractCTZLiBP746Meta } from "/app/pages/analysis/shop/bloggerInteract.vue?macro=true";
import { default as _91id_93jhLEC1HqhnMeta } from "/app/pages/analysis/shop/detail/[id].vue?macro=true";
import { default as hostingZl8UY9gg8gMeta } from "/app/pages/analysis/shop/hosting.vue?macro=true";
import { default as hotSaleAhiRrB7sPXMeta } from "/app/pages/analysis/shop/hotSale.vue?macro=true";
import { default as indexePe1Rp0qLFMeta } from "/app/pages/analysis/shop/index.vue?macro=true";
import { default as _91id_93KTHUBNq7d5Meta } from "/app/pages/analysis/video/detail/[id].vue?macro=true";
import { default as ecommerceessWGKbLmBMeta } from "/app/pages/analysis/video/ecommerce.vue?macro=true";
import { default as hotHmiq9TiS3SMeta } from "/app/pages/analysis/video/hot.vue?macro=true";
import { default as indexyg5FbxbnzUMeta } from "/app/pages/analysis/video/index.vue?macro=true";
import { default as blogger9WubeqXVr2Meta } from "/app/pages/favourites/blogger.vue?macro=true";
import { default as liver3gvoCoFJZMeta } from "/app/pages/favourites/live.vue?macro=true";
import { default as productx8BV4WBV7FMeta } from "/app/pages/favourites/product.vue?macro=true";
import { default as shophd5slMypvlMeta } from "/app/pages/favourites/shop.vue?macro=true";
import { default as videosId3EEkwbsMeta } from "/app/pages/favourites/video.vue?macro=true";
import { default as favourites2FBJJbkfd4Meta } from "/app/pages/favourites.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as privacyg3gwBRGXatMeta } from "/app/pages/privacy.vue?macro=true";
import { default as infoaWsS3RStYaMeta } from "/app/pages/user/info.vue?macro=true";
import { default as memberSMZBlN5QGVMeta } from "/app/pages/user/member.vue?macro=true";
import { default as order4BxZtw60EvMeta } from "/app/pages/user/order.vue?macro=true";
import { default as userCXh5rkXRCzMeta } from "/app/pages/user.vue?macro=true";
export default [
  {
    name: "agreement___en",
    path: "/en/agreement",
    meta: agreementY82GsiR81fMeta || {},
    component: () => import("/app/pages/agreement.vue").then(m => m.default || m)
  },
  {
    name: "agreement___zh___default",
    path: "/agreement",
    meta: agreementY82GsiR81fMeta || {},
    component: () => import("/app/pages/agreement.vue").then(m => m.default || m)
  },
  {
    name: "agreement___zh",
    path: "/zh/agreement",
    meta: agreementY82GsiR81fMeta || {},
    component: () => import("/app/pages/agreement.vue").then(m => m.default || m)
  },
  {
    name: "analysis-blogger-detail-id___en",
    path: "/en/analysis/blogger/detail/:id()",
    meta: _91id_933W08RmjplwMeta || {},
    component: () => import("/app/pages/analysis/blogger/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "analysis-blogger-detail-id___zh___default",
    path: "/analysis/blogger/detail/:id()",
    meta: _91id_933W08RmjplwMeta || {},
    component: () => import("/app/pages/analysis/blogger/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "analysis-blogger-detail-id___zh",
    path: "/zh/analysis/blogger/detail/:id()",
    meta: _91id_933W08RmjplwMeta || {},
    component: () => import("/app/pages/analysis/blogger/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "analysis-blogger-ecommerce___en",
    path: "/en/analysis/blogger/ecommerce",
    component: () => import("/app/pages/analysis/blogger/ecommerce.vue").then(m => m.default || m)
  },
  {
    name: "analysis-blogger-ecommerce___zh___default",
    path: "/analysis/blogger/ecommerce",
    component: () => import("/app/pages/analysis/blogger/ecommerce.vue").then(m => m.default || m)
  },
  {
    name: "analysis-blogger-ecommerce___zh",
    path: "/zh/analysis/blogger/ecommerce",
    component: () => import("/app/pages/analysis/blogger/ecommerce.vue").then(m => m.default || m)
  },
  {
    name: "analysis-blogger-increaseFans___en",
    path: "/en/analysis/blogger/increaseFans",
    component: () => import("/app/pages/analysis/blogger/increaseFans.vue").then(m => m.default || m)
  },
  {
    name: "analysis-blogger-increaseFans___zh___default",
    path: "/analysis/blogger/increaseFans",
    component: () => import("/app/pages/analysis/blogger/increaseFans.vue").then(m => m.default || m)
  },
  {
    name: "analysis-blogger-increaseFans___zh",
    path: "/zh/analysis/blogger/increaseFans",
    component: () => import("/app/pages/analysis/blogger/increaseFans.vue").then(m => m.default || m)
  },
  {
    name: "analysis-blogger___en",
    path: "/en/analysis/blogger",
    meta: index1JzR77fDk9Meta || {},
    component: () => import("/app/pages/analysis/blogger/index.vue").then(m => m.default || m)
  },
  {
    name: "analysis-blogger___zh___default",
    path: "/analysis/blogger",
    meta: index1JzR77fDk9Meta || {},
    component: () => import("/app/pages/analysis/blogger/index.vue").then(m => m.default || m)
  },
  {
    name: "analysis-blogger___zh",
    path: "/zh/analysis/blogger",
    meta: index1JzR77fDk9Meta || {},
    component: () => import("/app/pages/analysis/blogger/index.vue").then(m => m.default || m)
  },
  {
    name: "analysis-live-detail-id___en",
    path: "/en/analysis/live/detail/:id()",
    meta: _91id_93eEl2pjURgnMeta || {},
    component: () => import("/app/pages/analysis/live/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "analysis-live-detail-id___zh___default",
    path: "/analysis/live/detail/:id()",
    meta: _91id_93eEl2pjURgnMeta || {},
    component: () => import("/app/pages/analysis/live/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "analysis-live-detail-id___zh",
    path: "/zh/analysis/live/detail/:id()",
    meta: _91id_93eEl2pjURgnMeta || {},
    component: () => import("/app/pages/analysis/live/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "analysis-live-ecommerce___en",
    path: "/en/analysis/live/ecommerce",
    component: () => import("/app/pages/analysis/live/ecommerce.vue").then(m => m.default || m)
  },
  {
    name: "analysis-live-ecommerce___zh___default",
    path: "/analysis/live/ecommerce",
    component: () => import("/app/pages/analysis/live/ecommerce.vue").then(m => m.default || m)
  },
  {
    name: "analysis-live-ecommerce___zh",
    path: "/zh/analysis/live/ecommerce",
    component: () => import("/app/pages/analysis/live/ecommerce.vue").then(m => m.default || m)
  },
  {
    name: "analysis-live___en",
    path: "/en/analysis/live",
    meta: indexObEO00WlCMMeta || {},
    component: () => import("/app/pages/analysis/live/index.vue").then(m => m.default || m)
  },
  {
    name: "analysis-live___zh___default",
    path: "/analysis/live",
    meta: indexObEO00WlCMMeta || {},
    component: () => import("/app/pages/analysis/live/index.vue").then(m => m.default || m)
  },
  {
    name: "analysis-live___zh",
    path: "/zh/analysis/live",
    meta: indexObEO00WlCMMeta || {},
    component: () => import("/app/pages/analysis/live/index.vue").then(m => m.default || m)
  },
  {
    name: "analysis-products-detail-id___en",
    path: "/en/analysis/products/detail/:id()",
    meta: _91id_937ognwgTcAhMeta || {},
    component: () => import("/app/pages/analysis/products/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "analysis-products-detail-id___zh___default",
    path: "/analysis/products/detail/:id()",
    meta: _91id_937ognwgTcAhMeta || {},
    component: () => import("/app/pages/analysis/products/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "analysis-products-detail-id___zh",
    path: "/zh/analysis/products/detail/:id()",
    meta: _91id_937ognwgTcAhMeta || {},
    component: () => import("/app/pages/analysis/products/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "analysis-products-hotPush___en",
    path: "/en/analysis/products/hotPush",
    component: () => import("/app/pages/analysis/products/hotPush.vue").then(m => m.default || m)
  },
  {
    name: "analysis-products-hotPush___zh___default",
    path: "/analysis/products/hotPush",
    component: () => import("/app/pages/analysis/products/hotPush.vue").then(m => m.default || m)
  },
  {
    name: "analysis-products-hotPush___zh",
    path: "/zh/analysis/products/hotPush",
    component: () => import("/app/pages/analysis/products/hotPush.vue").then(m => m.default || m)
  },
  {
    name: "analysis-products-hotSale___en",
    path: "/en/analysis/products/hotSale",
    component: () => import("/app/pages/analysis/products/hotSale.vue").then(m => m.default || m)
  },
  {
    name: "analysis-products-hotSale___zh___default",
    path: "/analysis/products/hotSale",
    component: () => import("/app/pages/analysis/products/hotSale.vue").then(m => m.default || m)
  },
  {
    name: "analysis-products-hotSale___zh",
    path: "/zh/analysis/products/hotSale",
    component: () => import("/app/pages/analysis/products/hotSale.vue").then(m => m.default || m)
  },
  {
    name: "analysis-products___en",
    path: "/en/analysis/products",
    component: () => import("/app/pages/analysis/products/index.vue").then(m => m.default || m)
  },
  {
    name: "analysis-products___zh___default",
    path: "/analysis/products",
    component: () => import("/app/pages/analysis/products/index.vue").then(m => m.default || m)
  },
  {
    name: "analysis-products___zh",
    path: "/zh/analysis/products",
    component: () => import("/app/pages/analysis/products/index.vue").then(m => m.default || m)
  },
  {
    name: "analysis-shop-bloggerInteract___en",
    path: "/en/analysis/shop/bloggerInteract",
    component: () => import("/app/pages/analysis/shop/bloggerInteract.vue").then(m => m.default || m)
  },
  {
    name: "analysis-shop-bloggerInteract___zh___default",
    path: "/analysis/shop/bloggerInteract",
    component: () => import("/app/pages/analysis/shop/bloggerInteract.vue").then(m => m.default || m)
  },
  {
    name: "analysis-shop-bloggerInteract___zh",
    path: "/zh/analysis/shop/bloggerInteract",
    component: () => import("/app/pages/analysis/shop/bloggerInteract.vue").then(m => m.default || m)
  },
  {
    name: "analysis-shop-detail-id___en",
    path: "/en/analysis/shop/detail/:id()",
    meta: _91id_93jhLEC1HqhnMeta || {},
    component: () => import("/app/pages/analysis/shop/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "analysis-shop-detail-id___zh___default",
    path: "/analysis/shop/detail/:id()",
    meta: _91id_93jhLEC1HqhnMeta || {},
    component: () => import("/app/pages/analysis/shop/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "analysis-shop-detail-id___zh",
    path: "/zh/analysis/shop/detail/:id()",
    meta: _91id_93jhLEC1HqhnMeta || {},
    component: () => import("/app/pages/analysis/shop/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "analysis-shop-hosting___en",
    path: "/en/analysis/shop/hosting",
    component: () => import("/app/pages/analysis/shop/hosting.vue").then(m => m.default || m)
  },
  {
    name: "analysis-shop-hosting___zh___default",
    path: "/analysis/shop/hosting",
    component: () => import("/app/pages/analysis/shop/hosting.vue").then(m => m.default || m)
  },
  {
    name: "analysis-shop-hosting___zh",
    path: "/zh/analysis/shop/hosting",
    component: () => import("/app/pages/analysis/shop/hosting.vue").then(m => m.default || m)
  },
  {
    name: "analysis-shop-hotSale___en",
    path: "/en/analysis/shop/hotSale",
    component: () => import("/app/pages/analysis/shop/hotSale.vue").then(m => m.default || m)
  },
  {
    name: "analysis-shop-hotSale___zh___default",
    path: "/analysis/shop/hotSale",
    component: () => import("/app/pages/analysis/shop/hotSale.vue").then(m => m.default || m)
  },
  {
    name: "analysis-shop-hotSale___zh",
    path: "/zh/analysis/shop/hotSale",
    component: () => import("/app/pages/analysis/shop/hotSale.vue").then(m => m.default || m)
  },
  {
    name: "analysis-shop___en",
    path: "/en/analysis/shop",
    meta: indexePe1Rp0qLFMeta || {},
    component: () => import("/app/pages/analysis/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "analysis-shop___zh___default",
    path: "/analysis/shop",
    meta: indexePe1Rp0qLFMeta || {},
    component: () => import("/app/pages/analysis/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "analysis-shop___zh",
    path: "/zh/analysis/shop",
    meta: indexePe1Rp0qLFMeta || {},
    component: () => import("/app/pages/analysis/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "analysis-video-detail-id___en",
    path: "/en/analysis/video/detail/:id()",
    meta: _91id_93KTHUBNq7d5Meta || {},
    component: () => import("/app/pages/analysis/video/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "analysis-video-detail-id___zh___default",
    path: "/analysis/video/detail/:id()",
    meta: _91id_93KTHUBNq7d5Meta || {},
    component: () => import("/app/pages/analysis/video/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "analysis-video-detail-id___zh",
    path: "/zh/analysis/video/detail/:id()",
    meta: _91id_93KTHUBNq7d5Meta || {},
    component: () => import("/app/pages/analysis/video/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "analysis-video-ecommerce___en",
    path: "/en/analysis/video/ecommerce",
    component: () => import("/app/pages/analysis/video/ecommerce.vue").then(m => m.default || m)
  },
  {
    name: "analysis-video-ecommerce___zh___default",
    path: "/analysis/video/ecommerce",
    component: () => import("/app/pages/analysis/video/ecommerce.vue").then(m => m.default || m)
  },
  {
    name: "analysis-video-ecommerce___zh",
    path: "/zh/analysis/video/ecommerce",
    component: () => import("/app/pages/analysis/video/ecommerce.vue").then(m => m.default || m)
  },
  {
    name: "analysis-video-hot___en",
    path: "/en/analysis/video/hot",
    component: () => import("/app/pages/analysis/video/hot.vue").then(m => m.default || m)
  },
  {
    name: "analysis-video-hot___zh___default",
    path: "/analysis/video/hot",
    component: () => import("/app/pages/analysis/video/hot.vue").then(m => m.default || m)
  },
  {
    name: "analysis-video-hot___zh",
    path: "/zh/analysis/video/hot",
    component: () => import("/app/pages/analysis/video/hot.vue").then(m => m.default || m)
  },
  {
    name: "analysis-video___en",
    path: "/en/analysis/video",
    component: () => import("/app/pages/analysis/video/index.vue").then(m => m.default || m)
  },
  {
    name: "analysis-video___zh___default",
    path: "/analysis/video",
    component: () => import("/app/pages/analysis/video/index.vue").then(m => m.default || m)
  },
  {
    name: "analysis-video___zh",
    path: "/zh/analysis/video",
    component: () => import("/app/pages/analysis/video/index.vue").then(m => m.default || m)
  },
  {
    name: "favourites___en",
    path: "/en/favourites",
    component: () => import("/app/pages/favourites.vue").then(m => m.default || m),
    children: [
  {
    name: "favourites-blogger___en",
    path: "blogger",
    component: () => import("/app/pages/favourites/blogger.vue").then(m => m.default || m)
  },
  {
    name: "favourites-live___en",
    path: "live",
    component: () => import("/app/pages/favourites/live.vue").then(m => m.default || m)
  },
  {
    name: "favourites-product___en",
    path: "product",
    component: () => import("/app/pages/favourites/product.vue").then(m => m.default || m)
  },
  {
    name: "favourites-shop___en",
    path: "shop",
    component: () => import("/app/pages/favourites/shop.vue").then(m => m.default || m)
  },
  {
    name: "favourites-video___en",
    path: "video",
    component: () => import("/app/pages/favourites/video.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "favourites___zh___default",
    path: "/favourites",
    component: () => import("/app/pages/favourites.vue").then(m => m.default || m),
    children: [
  {
    name: "favourites-blogger___zh___default",
    path: "blogger",
    component: () => import("/app/pages/favourites/blogger.vue").then(m => m.default || m)
  },
  {
    name: "favourites-live___zh___default",
    path: "live",
    component: () => import("/app/pages/favourites/live.vue").then(m => m.default || m)
  },
  {
    name: "favourites-product___zh___default",
    path: "product",
    component: () => import("/app/pages/favourites/product.vue").then(m => m.default || m)
  },
  {
    name: "favourites-shop___zh___default",
    path: "shop",
    component: () => import("/app/pages/favourites/shop.vue").then(m => m.default || m)
  },
  {
    name: "favourites-video___zh___default",
    path: "video",
    component: () => import("/app/pages/favourites/video.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "favourites___zh",
    path: "/zh/favourites",
    component: () => import("/app/pages/favourites.vue").then(m => m.default || m),
    children: [
  {
    name: "favourites-blogger___zh",
    path: "blogger",
    component: () => import("/app/pages/favourites/blogger.vue").then(m => m.default || m)
  },
  {
    name: "favourites-live___zh",
    path: "live",
    component: () => import("/app/pages/favourites/live.vue").then(m => m.default || m)
  },
  {
    name: "favourites-product___zh",
    path: "product",
    component: () => import("/app/pages/favourites/product.vue").then(m => m.default || m)
  },
  {
    name: "favourites-shop___zh",
    path: "shop",
    component: () => import("/app/pages/favourites/shop.vue").then(m => m.default || m)
  },
  {
    name: "favourites-video___zh",
    path: "video",
    component: () => import("/app/pages/favourites/video.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___zh___default",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___zh",
    path: "/zh",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    meta: privacyg3gwBRGXatMeta || {},
    component: () => import("/app/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___zh___default",
    path: "/privacy",
    meta: privacyg3gwBRGXatMeta || {},
    component: () => import("/app/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___zh",
    path: "/zh/privacy",
    meta: privacyg3gwBRGXatMeta || {},
    component: () => import("/app/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "user___en",
    path: "/en/user",
    component: () => import("/app/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: "user-info___en",
    path: "info",
    meta: infoaWsS3RStYaMeta || {},
    component: () => import("/app/pages/user/info.vue").then(m => m.default || m)
  },
  {
    name: "user-member___en",
    path: "member",
    component: () => import("/app/pages/user/member.vue").then(m => m.default || m)
  },
  {
    name: "user-order___en",
    path: "order",
    component: () => import("/app/pages/user/order.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user___zh___default",
    path: "/user",
    component: () => import("/app/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: "user-info___zh___default",
    path: "info",
    meta: infoaWsS3RStYaMeta || {},
    component: () => import("/app/pages/user/info.vue").then(m => m.default || m)
  },
  {
    name: "user-member___zh___default",
    path: "member",
    component: () => import("/app/pages/user/member.vue").then(m => m.default || m)
  },
  {
    name: "user-order___zh___default",
    path: "order",
    component: () => import("/app/pages/user/order.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user___zh",
    path: "/zh/user",
    component: () => import("/app/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: "user-info___zh",
    path: "info",
    meta: infoaWsS3RStYaMeta || {},
    component: () => import("/app/pages/user/info.vue").then(m => m.default || m)
  },
  {
    name: "user-member___zh",
    path: "member",
    component: () => import("/app/pages/user/member.vue").then(m => m.default || m)
  },
  {
    name: "user-order___zh",
    path: "order",
    component: () => import("/app/pages/user/order.vue").then(m => m.default || m)
  }
]
  }
]