import { localizedNeedLoginPath } from '~/utils/common'

export default defineNuxtPlugin(() => {
  const router = useRouter()
  const user = useUser()
  const localePath = useLocalePath()
  router.beforeEach((to, _, next) => {
    if (localizedNeedLoginPath().includes(to.path) && !user.value.info?.id) {
      router.replace(localePath('/analysis/products'))
      next(localePath('/analysis/products'))
    }
    else {
      next()
    }
  })
})
