import { Modal } from '@arco-design/web-vue'
import OffLine from '~/components/identity/OffLine.vue'

export function useOffLineModal() {
  const isModalOpen = useState('isModalOpen', () => false)
  const { $i18n } = useNuxtApp()
  const { logout, toggleDialog } = useLogin()
  function closeModal(val = false) {
    isModalOpen.value = false
    logout()
    if (val) {
      toggleDialog(true, true)
      return
    }
    location.reload() // 同账号登录状态要刷新
  }
  return {
    open: () => {
      if (isModalOpen.value) {
        return
      }
      const offlineModal = Modal.open({
        title: $i18n.t('下线通知'),
        skip: true,
        footer: false,
        maskClosable: false,
        hideCancel: true,
        width: 400,
        content: () => h(
          OffLine,
          {
            onOk: () => {
              closeModal(true)
              offlineModal.close()
            },
          },
        ),
        onCancel: () => {
          closeModal()
          offlineModal.close()
        },
      })
      isModalOpen.value = true
    },
  }
}
