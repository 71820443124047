import revive_payload_client_x71jya2KRK from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_less@4.2.0_rollup@4.20.0_typescript@5.5.4_vite@5.4.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_AGzZculcRQ from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_less@4.2.0_rollup@4.20.0_typescript@5.5.4_vite@5.4.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_PNi0GQmqkS from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_less@4.2.0_rollup@4.20.0_typescript@5.5.4_vite@5.4.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_wvSnCeNZGP from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_less@4.2.0_rollup@4.20.0_typescript@5.5.4_vite@5.4.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_jlHKt4RHhj from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_less@4.2.0_rollup@4.20.0_typescript@5.5.4_vite@5.4.0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_1BRmGAZeAT from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_less@4.2.0_rollup@4.20.0_typescript@5.5.4_vite@5.4.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_H6psqfeTvI from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_less@4.2.0_rollup@4.20.0_typescript@5.5.4_vite@5.4.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_XYeBIR9HsY from "/app/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_less@4.2.0_rollup@4.20.0_typescript@5.5.4_vite@5.4.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_f4gtl4N964 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.20.0_vue@3.4.37/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_q6ELgViU4R from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.20.0_vue@3.4.37/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import unocss_MzCDxu9LMj from "/app/.nuxt/unocss.mjs";
import app_client_jL6aHSvptc from "/app/plugins/app.client.tsx";
import echarts_client_0ubMsSY37x from "/app/plugins/echarts.client.ts";
import filter_options_i49vr1AsPQ from "/app/plugins/filter-options.ts";
import request_8cwBTcUfTa from "/app/plugins/request.ts";
import router_client_W01CLn04zL from "/app/plugins/router.client.ts";
import vue_query_wrmMkNpEpe from "/app/plugins/vue-query.ts";
export default [
  revive_payload_client_x71jya2KRK,
  unhead_AGzZculcRQ,
  router_PNi0GQmqkS,
  payload_client_wvSnCeNZGP,
  navigation_repaint_client_jlHKt4RHhj,
  check_outdated_build_client_1BRmGAZeAT,
  chunk_reload_client_H6psqfeTvI,
  components_plugin_KR1HBZs4kY,
  prefetch_client_XYeBIR9HsY,
  switch_locale_path_ssr_f4gtl4N964,
  i18n_q6ELgViU4R,
  unocss_MzCDxu9LMj,
  app_client_jL6aHSvptc,
  echarts_client_0ubMsSY37x,
  filter_options_i49vr1AsPQ,
  request_8cwBTcUfTa,
  router_client_W01CLn04zL,
  vue_query_wrmMkNpEpe
]