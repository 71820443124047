import { localizedNeedLoginPath } from '~/utils/common'

export function useLogin() {
  const { $request } = useNuxtApp()
  const visible = useState('login-visible', () => false)
  const isReload = useState('is-$requesteload', () => false)
  const route = useRoute()
  const user = useUser()
  function toggleDialog(val: boolean, reload = false) {
    visible.value = val
    isReload.value = reload
  }
  const isLogin = computed(() => !!user.value.isLogin)
  const isExpired = computed(() => {
    const moduleInfo = user.value.info?.privileges?.find((item: any) => item.moduleId === '1')
    return !isLogin.value ? false : !user.value.info?.privileges?.length || !moduleInfo?.privilegeValue
  })
  watch(() => visible.value, (val) => {
    if (!val && !isLogin.value) {
      // eslint-disable-next-line ts/no-unused-expressions
      isReload.value && location.reload()
    }
  })
  async function refetchUserInfo() {
    await useRequest<Api.User.Info>('/user/info', { key: 'userInfo' })
  }
  function setUserInfo(data?: Api.User.Info) {
    const user = useUser()
    user.value.info = data ?? {
      id: 0,
      name: 'v',
      phone: '13611111111',
      createTime: '1704038400000',
      isNewUser: 0,
      userRole: 1,
      permissions: [],
      privileges: [],
      accessToken: '',
    } as Api.User.Info
    user.value.isLogin = user.value.info?.id > 0
  }
  const isExpiredOrUnLogin = computed(() => !isLogin.value || isExpired.value)
  async function logout() {
    try {
      await $request<any>('/user/logout')
      await refetchUserInfo()
      const localePath = useLocalePath()
      if (localizedNeedLoginPath().includes(route.path)) {
        navigateTo(localePath('/analysis/products'))
      }
    }
    catch (e) {
      console.error(e)
    }
  }

  return {
    visible,
    isLogin,
    isExpired,
    isExpiredOrUnLogin,
    setUserInfo,
    logout,
    toggleDialog,
    refetchUserInfo,
  }
}
