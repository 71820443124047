import { Drawer, Modal } from '@arco-design/web-vue'
import GModalHeader from '~/components/g/modal/Header.vue'
import GModalFooter from '~/components/g/modal/Footer.vue'

export default defineNuxtPlugin(() => {
  const { vueApp } = useNuxtApp()

  Modal._context = vueApp._context
  const open = Modal.open
  Modal.open = (config, appContent) => {
    const { toggleDialog, isExpiredOrUnLogin, isLogin } = useLogin()
    const { toggleExpried } = useExpired()
    // 检查用户是否未登录或状态已过期
    if (isExpiredOrUnLogin.value && !(config as any).skip) {
      if (!isLogin.value) {
        return toggleDialog(true)
      }
      return toggleExpried(true)
    }
    const title = config.title
    const footer = config.footer

    config.titleAlign = config.titleAlign ?? 'start'
    config.simple = config.simple ?? true
    config.modalStyle = { padding: '0', borderRadius: '8px', ...config.modalStyle }
    config.bodyStyle = config.bodyStyle || { padding: '0 24px' }

    const modalReturn = open({
      ...config,
      title: typeof title === 'string' ? () => <GModalHeader title={title} onClose={onCancel} /> : title,
      footer: footer !== false && typeof footer !== 'function' ? () => <GModalFooter onCancel={onCancel} onOk={onOk} /> : footer,
    }, appContent)

    function onCancel(...args: any[]) {
      config.onCancel?.(...args)
      modalReturn.close()
    }

    function onOk(...args: any[]) {
      config.onOk?.(...args)
      modalReturn.close()
    }

    return modalReturn
  }

  Drawer._context = vueApp._context
  const drawerOpen = Drawer.open
  Drawer.open = (config, appContent) => {
    const title = config.title

    const modalReturn = drawerOpen({
      ...config,
      header: config.header || (
        () => typeof title === 'string'
          ? <GModalHeader class="h-48 b-0 p-0 text-16 font-500" title={title} onClose={onCancel} />
          : <GModalHeader class="h-48 b-0 p-0 text-16 font-500" onClose={onCancel}>{{ default: title }}</GModalHeader>
      ),
    }, appContent)

    function onCancel(...args: any[]) {
      config.onCancel?.(...args)
      modalReturn.close()
    }

    // function onOk(...args: any[]) {
    //   config.onOk?.(...args)
    //   modalReturn.close()
    // }

    return modalReturn
  }
})
