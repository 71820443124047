import type { FetchResponse, ResponseType } from 'ofetch'
import { parseQuery, parseURL, stringifyParsedURL } from 'ufo'
import { omitBy } from 'lodash-es'
import { Message } from '@arco-design/web-vue'

export default defineNuxtPlugin(() => {
  const { apiV2 } = useRuntimeConfig().public

  const request = $fetch.create({
    baseURL: apiV2,
    retry: false,

    onRequest: (ctx) => {
      if (import.meta.server) {
        // Forward cookies to the target
        ctx.options.headers = useRequestHeaders(['cookie'])
      }

      if (ctx.options.query) {
        // 👇过滤掉值为 undefined || null || '' || -1 的参数
        ctx.options.query = omitBy(
          ctx.options.query,
          val => typeof val === 'undefined' || val === null || val === '' || val === -1,
        )

        // 👇自己处理query的原因
        // https://github.com/unjs/ufo/issues/154
        const url = parseURL(ctx.request.toString())
        const query = new URLSearchParams()
        Object
          .entries({ ...parseQuery(url.search), ...ctx.options.query })
          .forEach(([key, value]) => {
            query.append(key, value)
          })
        url.search = query.toString()
        ctx.request = stringifyParsedURL(url)
        ctx.options.query = undefined // cleaning up the query so that ofetch doesn't process it again
      }
    },

    onResponse: ({ response, options }) => {
      if (options.responseType === 'blob') {
        response._data = {
          data: response._data,
          headers: response.headers,
        }
      }
      else if (response._data.success) {
        const success = response._data.success
        const result = success.isCrypto ? JSON.parse(decryptData(success.result)) : success.result
        response._data = result
      }
      else {
        handleError(response)
      }
    },

    onRequestError: ({ request, error }) => {
      if (error.name !== 'AbortError') {
        console.error('[RequestError]', request, error.message, error)
      }
    },

    onResponseError: ({ response }) => {
      const { _data } = response
      if (_data && _data.data instanceof Blob) {
        // 处理文件流错误
        const reader = new FileReader()
        reader.onloadend = () => {
          const result = reader.result
          try {
            const errorData = JSON.parse(result as string)
            response._data.fault = errorData?.fault
            handleError(response)
          }
          catch {
            response._data.fault = { message: 'Failed to parse error data' }
            handleError(response)
          }
        }
        reader.readAsText(_data.data)
      }
      else {
        throw response?._data?.fault
      }
    },
  })

  function handleError(response: FetchResponse<any> & FetchResponse<ResponseType>) {
    const err = (text?: string) => {
      if (import.meta.client) {
        Message.error(response?._data?.fault?.message ?? text)
      }
      else {
        console.error(response?._data?.fault?.message ?? text)
      }
    }
    if (!response._data) {
      err('Request Timeout! The Server did not respond.')
      return
    }
    const { toggleDialog, setUserInfo } = useLogin()
    const { toggleExpried } = useExpired()
    const { open } = useOffLineModal()
    const route = useRoute()
    const noModals = route.meta.noModals
    const handleStatusMap: { [key: number]: () => void } = {
      401: () => {
        const { code } = response?._data?.fault
        if (code >= 40100 && code <= 40109) { // 401代表未登录，其他状态暂不处理
          setUserInfo()
          if (code === 40101) {
            open()
            return
          }
          // eslint-disable-next-line ts/no-unused-expressions
          !noModals && toggleDialog(true)
        }
        else if (code >= 40110 && code <= 40119) {
          // isExpired.value = true
          // eslint-disable-next-line ts/no-unused-expressions
          !noModals && toggleExpried(true)
        }
        else {
          err()
        }
      },
    }

    const handler = handleStatusMap[response.status]
    if (handler) {
      handler()
    }
    else {
      err('Unknown Error!')
    }
  }

  // Expose to useNuxtApp().$request
  return {
    provide: {
      request,
    },
  }
})
