export function goDetail(type: Exclude<Api.Dim.Type, 'product'> | 'products', id: string) {
  if (!id)
    return
  const { baseURL } = useRuntimeConfig().app
  const localePath = useLocalePath()
  navigateTo(`${baseURL === '/' ? '' : baseURL}${localePath(`/analysis/${type}/detail/${id}`)}`, { open: { target: '_blank' } })
}
export const needLoginPath = ['/user/info'] // Add paths that require login
export function localizedNeedLoginPath() {
  const localePath = useLocalePath()
  return needLoginPath.map(path => localePath(path))
}
