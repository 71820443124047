import { formatNumber, formatNumberWithUnit } from '~/utils/number'

export default defineNuxtPlugin(() => {
  const { $i18n } = useNuxtApp()

  return {
    provide: {
      getFilterOptions: () => ({
        locales: [
          { label: $i18n.t('简体中文'), value: 'zh' },
          { label: 'English', value: 'en' },
        ] as Option[],

        fansCount: [
          { label: $i18n.t('小于', [formatNumberWithUnit(10000)]), maxValue: 10000 },
          { label: `${formatNumber(10000)[0]}-${formatNumberWithUnit(100000)}`, minValue: 10000, maxValue: 100000 },
          { label: `${formatNumberWithUnit(100000)}-${formatNumberWithUnit(1000000)}`, minValue: 100000, maxValue: 1000000 },
          { label: `${formatNumber(1000000)[0]}-${formatNumberWithUnit(5000000)}`, minValue: 1000000, maxValue: 5000000 },
          { label: `${formatNumber(5000000)[0]}-${formatNumberWithUnit(10000000)}`, minValue: 5000000, maxValue: 10000000 },
          { label: $i18n.t('以上', [formatNumberWithUnit(10000000)]), minValue: 10000000 },
        ],

        ages: [
          { label: `18-24${$i18n.t('居多')}`, value: '1' },
          { label: `25-34${$i18n.t('居多')}`, value: '2' },
          { label: `35-44${$i18n.t('居多')}`, value: '3' },
          { label: `45-54${$i18n.t('居多')}`, value: '4' },
          { label: `55+${$i18n.t('居多')}`, value: '5' },
        ],
      }),
    },
  }
})
