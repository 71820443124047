<script setup lang="ts">
import dayjs from 'dayjs'
import { useQueryClient } from '@tanstack/vue-query'
import utc from 'dayjs/plugin/utc'
import zhCN from '@arco-design/web-vue/es/locale/lang/zh-cn'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'

dayjs.extend(utc)

const { locale } = useI18n()
const localeObj = computed(() => {
  return {
    zh: zhCN,
    en: enUS,
  }[locale.value]
})

const user = useUser()
const queryClient = useQueryClient()
const [dataTimeliness, userInfo, regions] = await Promise.all([
  useRequest<Api.Dim.DataTimeliness>('/dim/info/dataTimeliness', { key: 'dataTimeliness' }),
  useRequest<Api.User.Info>('/user/info', { key: 'userInfo' }),
  useRequest<{ data: Api.User.Region[] }>('/user/regions', { key: 'regions' }),
])

if (dataTimeliness.data.value) {
  user.value.dataTimeliness = dataTimeliness.data.value
}
if (regions.data.value) {
  user.value.regions = regions.data.value.data || []
}

watch(userInfo.data, (data) => {
  user.value.info = data ?? {
    id: 0,
    name: 'v',
    phone: '13611111111',
    createTime: '1704038400000',
    isNewUser: 0,
    userRole: 1,
    permissions: [],
    privileges: [],
    accessToken: '',
  } as unknown as Api.User.Info
  user.value.isLogin = user.value.info.id > 0
}, { immediate: true })
watch(() => user.value.isLogin, () => {
  // 登录状态变化重新请求
  nextTick(() => {
    queryClient.refetchQueries()
  })
})
</script>

<template>
  <a-config-provider :locale="localeObj" global>
    <NuxtLayout>
      <NuxtPage :keepalive="false" />
      <Login />
      <IdentityExpiredDialog />
    </NuxtLayout>
  </a-config-provider>
</template>
