<script lang="ts" setup>
const emit = defineEmits(['ok'])
</script>

<template>
  <div class="text-16">
    <div class="mb-20 indent-26 text-[--color-text-1] font-500 lh-30">
      <img src="~/assets/images/identity/tip.png" class="absolute left-26 top-6 mr-8 w-16">{{ $t('您的账号在另一地点登录，您被迫下线，') }}<br>
      {{ $t('为了账号安全，本次登录已失效，请重新登录') }}
    </div>
    <div class="ml--24 mr--24 mt-16 box-border flex justify-end gap-8 b-t-1 b-t-[#F2F3F5] b-t-solid px-24 py-16">
      <a-button class="h-28 rd-16 px-6 text-14" type="primary" @click="emit('ok')">
        {{ $t('重新登录') }}
      </a-button>
    </div>
  </div>
</template>
