export function useExpired() {
  const showExpried = useState('showExpried', () => false)
  function toggleExpried(val: boolean = false) {
    showExpried.value = val
  }
  const tableMask = ref(false)
  function toggleTableMask(code?: number) {
    tableMask.value = !code ? false : code >= 40100 && code <= 40119
  }
  return {
    showExpried,
    toggleExpried,
    tableMask,
    toggleTableMask,
  }
}
