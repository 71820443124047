import { isNaN, isNil } from 'lodash-es'
import bigjs from 'big.js'
import dayjs from 'dayjs'
/**
 * 将处理数据显示成40k+，40万+，40亿+的形式
 * @param value - 数值
 * @param digit - 保留的小数位数（可选，默认为2）
 * @returns {string}
 */
export function formatNumber(value: any, digit = 1) {
  if (isNil(value) || isNaN(value)) {
    return []
  }

  const { $i18n } = useNuxtApp()

  let valueString = typeof value === 'string' ? value.replace(/,/g, '').replace(/\.00$/, '') : value?.toString()
  let unit = ''
  // 检查值是否为负数
  const isNegative = Number.parseFloat(valueString) < 0
  value = Number.parseFloat(value)
  if (isNegative) {
    value = -value // 将负数转换为正数
  }

  // 中文单位
  if ($i18n.locale.value === 'zh') {
    if (value >= 1e8) {
      unit = '亿'
      valueString = (value / 1e8).toFixed(2)
    }
    else if (value >= 1e4) {
      unit = '万'
      valueString = (value / 1e4).toFixed(digit)
    }
    else {
      valueString = value.toFixed(digit)
    }
  }
  else {
    if (value >= 1e12) {
      unit = 'T'
      valueString = (value / 1e12).toFixed(2)
    }
    else if (value >= 1e9) {
      unit = 'B'
      valueString = (value / 1e9).toFixed(2)
    }
    else if (value >= 1e6) {
      unit = 'M'
      valueString = (value / 1e6).toFixed(digit)
    }
    else if (value >= 1e3) {
      unit = 'K'
      valueString = (value / 1e3).toFixed(digit)
    }
    else {
      valueString = value.toFixed(digit)
    }
  }

  if (isNegative) {
    valueString = `-${valueString}` // 添加负号
  }

  // $1：替换掉匹配的部分，保留小数点后非零部分。
  valueString = valueString.replace(/(\.\d*?[1-9])0+|\.0+$/, '$1')

  return [Number(valueString), unit]
}

export function formatNumberWithUnit(value: any, digit = 1) {
  const res = formatNumber(value, digit).join('')
  return res === 'NaN' ? '-' : res || '-'
}

// 把时长转换成小时、分钟和秒数
export function formatMillisecondsToHMS(milliseconds: number) {
  const { t } = useI18n()
  // 总秒数
  const totalSeconds = Math.floor(milliseconds / 1000)

  // 计算小时、分钟和秒
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  // 格式化输出
  let formatted = ''

  if (hours > 0) {
    formatted += `${hours}${t('时')}`
  }
  if (minutes > 0 || hours > 0) {
    formatted += `${minutes}${t('分')}`
  }
  formatted += `${seconds}${t('秒')}`

  return formatted.trim()
}

/**
 * 根据过滤键和映射生成过滤选项的标签
 * @param key 过滤选项的键
 * @param option 包含过滤选项映射的对象
 * @param filter 当前应用的过滤条件
 * @returns 返回生成的标签对象或null
 */
export function buildOptionLabel(key: string, option: { [x: string]: any }, filter: Record<string, any>) {
  let label = option.label
  // 从过滤条件中获取最小值和最大值
  const prefix = option.prefix || 'min'
  const suffix = option.suffix || 'max'
  // 如果是单选类型
  if (option.isRadio) {
    const selectedRadioOption = option.options?.find((opt: { value: any }) => opt.value === filter[key])
    return selectedRadioOption ? { label: `${label}: ${selectedRadioOption.label}`, value: key, prefix, suffix } : null
  }
  const minValue = filter[`${prefix}${key}`] as number | undefined
  const maxValue = filter[`${suffix}${key}`] as number | undefined
  if (minValue === undefined && maxValue === undefined) {
    return null
  }
  // 获取选项的单位，默认为空
  const unit = option.unit || ''
  const { $i18n } = useNuxtApp()
  const selectOpt = option.options?.find((item: { maxValue?: number, minValue?: number }) => item.maxValue === maxValue && item.minValue === minValue)
  if (selectOpt) {
    return { label: `${$i18n.t(label)}: ${selectOpt.label}`, value: key, prefix, suffix }
  }
  // 根据最小值和最大值生成标签
  if (key === 'FirstDateCode') {
    label = `${label}: ${dayjs(minValue).format('YYYY/MM/DD')}-${dayjs(maxValue).format('YYYY/MM/DD')}`
  }
  else if (minValue !== undefined && maxValue !== undefined) {
    label = `${label}: ${unit}${minValue}-${unit}${maxValue}`
  }
  else if (minValue !== undefined) {
    label = `${label}: ${$i18n.t('以上', [`${unit}${minValue}`])}`
  }
  else if (maxValue !== undefined) {
    label = `${label}: ${$i18n.t('以下', [`${unit}${maxValue}`])}`
  }

  return label ? { label, value: key, prefix, suffix } : null
}

/**
 * 百分数转成小数
 */
export function percentToDecimal(percent: string) {
  // 去掉百分号并将百分数转换为数字
  const value = bigjs(percent.replace('%', ''))

  // 将百分数转换为小数
  return value.div(100).toNumber()
}

/**
 * 小数转成百分数
 *
 * @param decimal 小数
 * @param fractionDigits 保留小数位数
 */
export function decimalToPercent(decimal: bigjs.BigSource, fractionDigits = 1) {
  // 去掉百分号并将百分数转换为数字
  const value = bigjs(decimal)

  // 将百分数转换为小数
  return `${formatNumberWithUnit(value.mul(100).toNumber(), fractionDigits)}%`
}

/**
 * 根据给定的比率格式化值
 *
 * @param value 待格式化的数值
 * @param conversionRate 用于将输入的值乘以该比率，默认为100
 * @returns 格式化后的数字值
 */
export function convertValueWithRate(value: bigjs.BigSource, conversionRate = 100) {
  return value ? bigjs(value).mul(conversionRate).toNumber() : value
}
