import CryptoJS from 'crypto-js'

const VARIABLE_KEY = '7d37a7d668b837d6622187a921fe8fc531eff7db0a452b32fcb4905db36fa3ab'

// aes加密
export function encryptData(data?: string) {
  if (!data)
    return ''
  const key = CryptoJS.enc.Hex.parse(VARIABLE_KEY)
  const ciphertext = CryptoJS.AES.encrypt(data, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString()

  // 将 Base64 编码后的字符串进行解码
  const ciphertextDecoded = CryptoJS.enc.Base64.parse(ciphertext)

  // 将解码后的内容进行 Hex 编码
  const ciphertextHex = ciphertextDecoded.toString(CryptoJS.enc.Hex)
  return ciphertextHex
}

// aes解密
export function decryptData(data: string) {
  const key = CryptoJS.enc.Hex.parse(VARIABLE_KEY)
  const ciphertext = CryptoJS.enc.Hex.parse(data)
  // eslint-disable-next-line ts/ban-ts-comment
  // @ts-expect-error
  const decrypted = CryptoJS.AES.decrypt({ ciphertext }, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
  const plaintext = decrypted.toString(CryptoJS.enc.Utf8)
  return plaintext
}
