<script setup lang="ts">
import QrcodeVue from 'qrcode.vue'
import { useQuery, useQueryClient } from '@tanstack/vue-query'

const user = useUser()
const { $request } = useNuxtApp()
// const { apiV2 } = useRuntimeConfig().public
const { visible } = useLogin()
const isTimeOut = ref(false)
const { data, isFetching, refetch, status } = useQuery({
  queryKey: ['/user/wx/official/qrCode', visible],
  queryFn: ({ signal }) => {
    const res = $request<Api.User.WxInfo>(
      `/user/wx/official/qrCode`,
      {
        // baseURL: apiV2,
        signal,
      },
    )
    isTimeOut.value = false
    return res
  },
  enabled: () => !!visible.value,
})
const timer = ref()
watch(() => status.value, (val) => {
  if (val === 'success' && data.value?.expireSeconds) {
    const expireMs = data.value?.expireSeconds
    timer.value = setTimeout(() => {
      isTimeOut.value = true
    }, expireMs)
  }
})
onUnmounted(() => clearTimeout(timer.value))
const queryKey: string[] = ['/user/wx/official/check']
const { data: statusInfo } = useQuery({
  queryKey: [...queryKey, data],
  queryFn: ({ signal }) => {
    return $request<Api.User.Info>(
      `/user/wx/official/check`,
      {
        // baseURL: apiV2,
        query: {
          ticket: data.value?.ticket,
        },
        signal,
      },
    )
  },
  enabled: () => !!data.value?.ticket && !isTimeOut.value,
  refetchInterval: 1000, // 每隔 1秒轮询一次
  refetchOnWindowFocus: false,
})
const queryClient = useQueryClient()
watch(() => statusInfo.value, (val) => {
  if (val?.openId && val?.unionId || val?.id) {
    // queryClient.invalidateQueries(queryKey)
    queryClient.resetQueries({ queryKey })
  }
  if (val?.id) {
    user.value.info = val
    visible.value = false
  }
})
</script>

<template>
  <div mb-40 flex flex-col items-center gap-36>
    <div class="flex justify-center text-28 text-[--color-text-5]">
      {{ $t('微信扫码登录') }}
    </div>
    <div class="h-220 w-220 border-1 border-[--color-border-1] rd-20 border-solid bg-white">
      <a-spin :loading="isFetching" class="relative h-full w-full flex items-center justify-center">
        <div
          v-if="isTimeOut && !isFetching"
          class="absolute left-16 top-16 h-188 w-188 flex flex-col cursor-pointer items-center justify-center gap-8 bg-[rgba(0,0,0,0.5)] text-white"
          @click="refetch"
        >
          <g-svg name="refresh" />
          {{ $t('刷新二维码') }}
        </div>
        <QrcodeVue :value="data?.url" :size="188" />
      </a-spin>
    </div>
    <div class="my-14 flex items-center text-12 text-[--color-text-3]">
      微信扫码<img src="~assets/images/login/wechat.svg" mx-2 w-16>关注公众号后即可登录注册
    </div>
  </div>
</template>

<style lang="less" scoped>

</style>
