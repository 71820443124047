<script setup lang="ts">
import { omitBy } from 'lodash-es'
import { Message } from '@arco-design/web-vue'

const props = withDefaults(defineProps<{
  type: number
  step: number
  isPassword: boolean
  isReset?: boolean
  size?: string
  data?: { phone?: string } // 用于修改密码时的手机号
}>(), {
  size: 'medium',
})
const emits = defineEmits(['change', 'forget', 'callback'])
function initForm(_form?: { phone?: string }) {
  return props.step === 2
    ? {
        phone: _form?.phone || '',
        password: '',
        rePassword: '',
        code: '',
      }
    : {
        phone: '',
        code: '',
      }
}
const form = ref(initForm())
const formRef = ref()
const hidden = ref(true)
const hidden1 = ref(true)
const { t } = useI18n()
watch(() => [props.type, props.step, props.isPassword], () => {
  formRef.value?.clearValidate()
  form.value = initForm(props.data)
  hidden.value = true
  hidden1.value = true
}, { immediate: true })
function validateEmail(email: string) {
  const emailRegex = /^[\w.-]+@[a-z0-9.-]+\.[a-z]{2,6}$/i
  return emailRegex.test(email)
}
const rules = computed(() => {
  const _tip = props.type === 2 ? '手机号' : '邮箱'
  const phoneRules = [
    { required: true, message: `*${t(`${_tip}不能为空`)}` },
    {
      validator: (value, cb) => {
        if (!value) {
          cb(`*${t(`${_tip}不能为空`)}`)
        }
        else if (props.type === 2 && !/^[+|1]\d{10,12}$/.test(value)) {
          cb(`*${t(`请输入有效的${_tip}`)}`)
        }
        else if (props.type === 3 && !validateEmail(value)) {
          cb(`*${t(`请输入有效的${_tip}`)}`)
        }
        else {
          cb()
        }
      },
    },
  ]
  return {
    phone: phoneRules,
    code: [
      { required: true, message: props.isPassword && props.step === 1 ? `*${t('密码不能为空')}` : `*${t('验证码不能为空')}` },
    ],
    password: [
      {
        required: true,
        message: `*${t('密码不能为空')}`,
      },
    ],
    rePassword: [
      {
        required: true,
        message: `*${t('密码不能为空')}`,
      },
      {
        validator: (value, cb) => {
          if (value !== form.value.password) {
            cb(`*${t('两次输入密码不一致，请重试')}`)
          }
          else {
            cb()
          }
        },
      },
    ],
  }
})
const { $request } = useNuxtApp()
const { visible, refetchUserInfo } = useLogin()
const loading = ref(false)
async function handleOk({ errors }) {
  if (errors) {
    return false
  }
  const url = props.isReset ? '/user/password/reset' : props.step === 2 ? '/user/register' : '/user/phone/login'
  const message = props.isReset ? '修改密码' : props.step === 2 ? '注册' : '登录'
  try {
    loading.value = true
    await $request<Api.User.Info>(url, {
      body: omitBy({
        ...form.value,
        type: props.type === 3 ? props.type : props.isPassword ? 1 : 2,
      }, val => val === ''),
      method: props.isReset ? 'PUT' : 'POST',
    })
    Message.success(t(`${message}成功`))
    await refetchUserInfo()
    emits('callback')
    visible.value = false
    loading.value = false
    return true
  }
  catch (error) {
    console.error('提交表单时发生错误:', error)
    loading.value = false
    return false
  }
}

const codeTimeNum = ref(0)
const codeTimeInterval = ref<NodeJS.Timeout | null>(null)
function getVeryCode() {
  if (codeTimeNum.value > 0) {
    return
  }
  if (!form.value.phone) {
    formRef.value?.validateField('phone')
    return
  }
  if (!/^[+|1]\d{10,12}$/.test(form.value.phone) && props.type === 2) {
    formRef.value?.validateField('phone')
    return
  }
  if (!validateEmail(form.value.phone) && props.type === 3) {
    formRef.value?.validateField('phone')
    return
  }

  codeTimeNum.value = 60
  codeTimeInterval.value = setInterval(() => {
    codeTimeNum.value--
    if (codeTimeNum.value <= 0) {
      clearCodeTimeNum()
    }
  }, 1000)
  getVerifyCode(form.value.phone)
}
async function getVerifyCode(phone: string) {
  const url = props.type === 3 ? '/user/email/send' : '/user/phone/sendSms'
  const query = props.type === 3 ? { email: phone } : { phone }
  try {
    await $request<Api.User.Info>(url, {
      query,
    })
    Message.success(t(`验证码发送成功${props.type === 3 ? '，请前往邮箱查看' : ''}`))
  }
  catch (error) {
    console.error('验证码获取失败:', error)
  }
}

function clearCodeTimeNum() {
  codeTimeNum.value = 0
  clearInterval(codeTimeInterval.value as NodeJS.Timeout)
}
defineExpose({
  resetFields: () => formRef.value?.resetFields(),
})
</script>

<template>
  <a-form
    ref="formRef"
    :rules="rules"
    :wrapper-col-props="{ span: 24 }"
    :hide-asterisk="true"
    :model="form"
    :size="size"
    @submit="handleOk"
  >
    <a-form-item field="phone" :hide-asterisk="true">
      <a-input
        v-model="form.phone"
        :disabled="!!data?.phone"
        :placeholder="$t(`请输入${type === 2 ? '手机号' : '邮箱'}`)"
      >
        <template #prefix>
          <g-svg :name="type === 2 ? 'login/phone-icon' : 'login/message-icon'" />
        </template>
      </a-input>
    </a-form-item>
    <a-form-item v-if="step === 2 || !isPassword" field="code" :hide-asterisk="true">
      <a-input
        v-model="form.code"
        :placeholder="$t('请输入验证码')"
      >
        <template #prefix>
          <g-svg name="login/code-icon" />
        </template>
        <template #suffix>
          <span class="cursor-pointer text-[--color-brand-6] hover:op-80" :class="{ 'text-#AC98E9 cursor-not-allowed': codeTimeNum }" @click="getVeryCode"> {{ codeTimeNum ? `${codeTimeNum}${$t('秒')}` : $t('获取验证码') }}</span>
        </template>
      </a-input>
    </a-form-item>
    <a-form-item v-if="step === 1 && isPassword" field="code" :hide-asterisk="true">
      <a-input
        v-model="form.code"
        :type="hidden ? 'password' : 'text'"
        :placeholder="$t('请输入密码')"
        autocomplete="new-password"
      >
        <template #prefix>
          <g-svg name="login/pwd-icon" />
        </template>
        <template #suffix>
          <g-svg :name="hidden ? 'login/hidden' : 'login/visible'" class="cursor-pointer text-[--color-text-4] !hover:text-[--color-brand-6]" @click="hidden = !hidden" />
        </template>
      </a-input>
    </a-form-item>
    <a-form-item v-if="step === 2" field="password" :hide-asterisk="true">
      <a-input
        v-model="form.password"
        :type="hidden ? 'password' : 'text'"
        :placeholder="$t('请输入密码')"
      >
        <template #prefix>
          <g-svg name="login/pwd-icon" />
        </template>
        <template #suffix>
          <g-svg :name="hidden ? 'login/hidden' : 'login/visible'" class="cursor-pointer text-[--color-text-4] !hover:text-[--color-brand-6]" @click="hidden = !hidden" />
        </template>
      </a-input>
    </a-form-item>
    <a-form-item v-if="step === 2" field="rePassword" validate-trigger="blur" :hide-asterisk="true">
      <a-input
        v-model="form.rePassword"
        :type="hidden1 ? 'password' : 'text'"
        :placeholder="$t('再次输入以确认密码')"
        autocomplete="new-password"
      >
        <template #prefix>
          <g-svg name="login/pwd-icon" />
        </template>
        <template #suffix>
          <g-svg :name="hidden1 ? 'login/hidden' : 'login/visible'" class="cursor-pointer text-[--color-text-4] !hover:text-[--color-brand-6]" @click="hidden1 = !hidden1" />
        </template>
      </a-input>
    </a-form-item>
    <a-form-item v-if="step === 1" class="mb-0">
      <div class="w-full flex justify-center px-4" :class="{ '!justify-between': type !== 3 && isPassword }">
        <span v-if="type === 2" class="cursor-pointer text-[--color-brand-6] hover:op-80" @click="emits('change')">{{ isPassword ? $t('验证码登录') : $t('密码登录') }}</span>
        <span v-if="type === 3 || isPassword" class="cursor-pointer text-[--color-text-3] hover:op-80" @click="emits('forget')">{{ $t('忘记密码') }}？</span>
      </div>
    </a-form-item>

    <!-- <a-form-item field="promotionCode" validate-trigger="blur">
      <a-input
        v-model="form.promotionCode"
        :style="{ width: '300px' }"
        placeholder="请输入邀请码（选填）"
      >
        <template #prefix>
          <g-svg name="login/invite-icon" />
        </template>
      </a-input>
    </a-form-item> -->
    <a-form-item v-if="size !== 'small'">
      <a-button :loading="loading" type="primary" html-type="submit" class="g-login-btn mt-20 h-54 w-full rd-28 text-24 font-500">
        {{ isReset ? $t('确定') : step === 2 ? $t('注册') : $t('登录') }}
      </a-button>
    </a-form-item>
    <slot :loading="loading" />
  </a-form>
</template>

<style scoped lang="less">
.g-login-btn {
  background: linear-gradient(99.82deg, #914DFA 17.49%, #681CF9 91.26%);
  &:hover {
    background: linear-gradient(99.82deg, #7B41D5 17.49%, #5919D4 91.26%);
  }
}
:deep(.arco-input-wrapper) {
  --color-danger-light-1: transparent;
  border: 1px solid var(--color-border-1) !important;
  height: 50px;
  overflow: hidden;
  border-radius: 24px;
  background: white;
 &:not(.arco-input-disabled):hover {
   border-color: var(--color-brand-6) !important ;
   background: transparent !important;
 }
 .arco-input-prefix {
   color: var(--color-text-3);
 }
}
.arco-form-size-small {
  .arco-input-wrapper {
    height: 40px !important;
    margin-bottom: 6px !important;
  }
}
:deep(.arco-form-item-message) {
  margin-left: 16px;
}
:deep(input::placeholder) {
  color: var(--color-text-4) !important; /* 修改placeholder的颜色 */
}
:deep(input:-webkit-autofill), :deep(select:-webkit-autofill) {
  -webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;
   background-color:transparent;
   background-image: none;
   transition: background-color 50000s ease-in-out 0s;
}
:deep(.arco-form-item-status-error .arco-input-wrapper:not(.arco-input-disabled)) {
  border-color: var(--color-danger-light-1);
  @apply: '!border-[--color-border-1]';
}
:deep(.arco-form-item:not(.arco-form-item-error):not(:last-child)) {
  margin-bottom: 10px;
}
</style>
