<script setup lang="ts">
const { showExpried } = useExpired()

function onClose() {
  showExpried.value = false
}
const { ENV } = useRuntimeConfig().public
const url = computed(() => {
  return ENV === 'prod' ? 'https://sellerv1.glodastory.com' : location.origin
})

function toPay() {
  window.open(`${url.value}/pay`, '_blank')
  onClose()
}
</script>

<template>
  <a-modal
    v-model:visible="showExpried"
    :width="400"
    simple
    hide-title
    :footer="false"
    :mask-closable="false"
    unmountOnClose
    modal-class="g-expired-dialog rd-16 p-0"
  >
    <div class="relative relative h-419 flex flex-col items-center justify-center">
      <div class="absolute right-16 top-16 h-28 w-28 flex cursor-pointer items-center justify-center text-14 hover:op-80" @click="onClose">
        <i class="icon-shanchu" />
      </div>
      <img class="w-172" src="~/assets/images/identity/expired.png" alt="">
      <div class="pt-24 text-20 text-[--color-text-1] font-500">
        {{ $t('您的会员已过期') }}
      </div>
      <div class="pt-12 text-16 text-[--color-text-2]">
        {{ $t('开通会员可查看更多数据') }}
      </div>
      <a-button class="mt-40 h-44 w-252 rd-22 text-16 font-500" type="primary" @click="toPay">
        {{ $t('立即开通') }}
      </a-button>
    </div>
  </a-modal>
</template>

<style lang="less">
.g-expired-dialog {
    background: linear-gradient(180deg, rgba(98, 52, 207, 0.30) 0%, rgba(98, 52, 207, 0.00) 39.45%), #FFF;
}
</style>
